import styled, { css } from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const MenuContainer = styled.div`
  position: absolute;
  width: 300px;
  background-color: white;
  height: 100vh;

  transition: 0.2s;
  transform: translateX(-324px);

  ${props =>
    props.open &&
    css`
      & {
        transform: translateX(0);
      }
    `}

  svg {
    position: absolute;
    top: 5px;
    right: 16px;
    font-size: 32px;
    color: #000;
    text-decoration: none;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 30px 0 0 16px;

    li {
      border-bottom: 1px solid rgba(53, 62, 84, 0.1);
      font-size: 20px;
      padding: 12px 0;

      a {
        color: initial;
        line-height: 20px;
        text-decoration: none;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
`;
