import styled from 'styled-components';

export const container = `
  width: 90%;
  max-width: 1200px;
  margin: 0px auto;
`;

export const simpleFlex = `
  display: flex;
  align-items: center;
`;

export const bannerDefault = `
  margin-top: 66px;
  background: linear-gradient(90deg, #093252 0%, #182d46 50%);
  min-height: 330px;
`;

export const Container = styled.div`
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 30px;
`;

export const Form = styled.form`
  position: relative;

  input {
    border: 1px solid #0b3152;
    padding: 14px 16px;
    background-color: white;
    border-radius: 24px;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.87);
  }
  select {
    border: 1px solid #0b3152;
    padding: 14px 16px;
    background-color: white;
    border-radius: 24px;
    font-size: 15px;
    color: #0b3152;
  }

  textarea {
    border: 1px solid #0b3152;
    padding: 14px 16px;
    background-color: white;
    border-radius: 24px;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.87);
  }
`;

export const Erro = styled.p`
  color: #ef4e22;
`;

export const ButtonFirst = styled.button`
  background: linear-gradient(90deg, #093252 0%, #182d46 50%);
  color: white;
  transition: 0.2s ease;
  padding: 12px 28px;
  border-radius: 24px;
  text-transform: uppercase;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  border: 0;
  @media (min-width: 484px) {
    margin-right: 20px;
  }
  font-weight: bold;

  &:hover {
    background: linear-gradient(90deg, #0c2e4d 0%, #09314f 50%);
    opacity: ${props => (props.disabled ? '0.2' : '0.9')};
  }

  opacity: ${props => (props.disabled ? '0.2' : '1')};
  cursor: ${props => (props.disabled ? 'no-drop' : 'pointer')};

  @media (max-width: 848px) {
    margin-bottom: 20px;
  }
`;

export const Button = styled.button`
  background: white;
  color: #0c2e4d;
  transition: 0.2s ease;
  padding: 12px 60px;
  border-radius: 24px;
  text-transform: uppercase;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  border: 0;
  font-weight: bold;

  &:hover {
    opacity: 0.9;
  }
`;

export const ButtonSecond = styled.button`
  background: white;
  color: #0c2e4d;
  padding: 12px 28px;
  border-radius: 24px;
  text-transform: uppercase;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  border: 0;
  font-weight: bold;

  &:hover {
    opacity: 0.9;
  }
`;
