import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const NavContainer = styled.div`
  ul {
    list-style: none;

    li {
      display: inline-block;
      margin: 0px 12px;
      transition: 0.2s;

      @media (max-width: 840px) {
        & {
          margin: 0px 5px;
        }
        &.hideMobile {
          display: none;
        }
      }

      @media (min-width: 992px) {
        &.sell {
          a {
            background-color: #0b3152;
            color: white !important;
            display: block;
            padding: 10px 38px;
            border-radius: 24px;
            border: 1px solid #284460;
            text-transform: uppercase;
          }
        }
      }

      a {
        color: initial;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
`;
