import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

import { WhatsappContainer } from './styles';

export default function WhatsappFloat() {
  return (
    <a
      rel="noopener noreferrer"
      target="_blank"
      className="whatsappFloat"
      onClick={() =>
        window.gtag('event', 'conversion', {
          send_to: 'AW-10813615298/S4SzCLbMhooDEMLZqqQo',
        })
      }
      href={`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_WHATSAPP_NUMBER}&text=Oi%2C%20Fideliti!`}
    >
      <WhatsappContainer>
        <FaWhatsapp className="btn-whatsapp-float"/>
      </WhatsappContainer>
    </a>
  );
}
