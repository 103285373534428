import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import { NavContainer } from './styles';

export default function Nav() {
  const {
    location: { pathname },
  } = useHistory();
  return (
    pathname !== '/' && (
      <NavContainer>
        <ul>
          <li>
            <Link to="/estoque">Estoque</Link>
          </li>
          <li className="hideMobile">
            <Link to="/busca_carro">Procurando algum carro?</Link>
          </li>
          <li className="hideMobile">
            <Link to="/contato">Contato</Link>
          </li>
          <li className="hideMobile">
            <Link to="/sobre">Sobre a Fideliti</Link>
          </li>
          <li className="sell">
            <Link to="/vendemos-seu-carro">Vender</Link>
          </li>
        </ul>
      </NavContainer>
    )
  );
}
