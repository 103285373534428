import React from 'react';
import { FaInstagram, FaFacebookF, FaPhone, FaMobileAlt } from 'react-icons/fa';

import Nav from '../Nav';
import { FooterContainer, Container } from './styles';

export default function Footer() {
  return (
    <FooterContainer>
      <Container>
        <div>
          <ul>
            <li>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://instagram.com/fidelitiveiculos"
              >
                <FaInstagram />
              </a>
            </li>
            <li>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.facebook.com/fidelitiveiculos"
              >
                <FaFacebookF />
              </a>
            </li>
            <li className="no-border">
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="tel:5130584600"
              >
                <FaPhone />
                (51) 3058-4600
              </a>
            </li>
            <li className="no-border">
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={`tel:${process.env.REACT_APP_WHATSAPP_NUMBER.substr(2)}`}
              >
                <FaMobileAlt />
                {`(${process.env.REACT_APP_WHATSAPP_NUMBER.substr(
                  2,
                  2
                )}) ${process.env.REACT_APP_WHATSAPP_NUMBER.substr(
                  4,
                  5
                )}-${process.env.REACT_APP_WHATSAPP_NUMBER.substr(9, 4)}`}
              </a>
            </li>
          </ul>
          <p>Fideliti - Todos os direitos reservados.</p>
        </div>
        <div>
          <Nav className="nav-footer" />
          <p>
            <br />
          </p>
        </div>
      </Container>
    </FooterContainer>
  );
}
