import React, { useState } from 'react';
import { FaBars } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import Logo from '../../assets/images/logo_blank.png';
import Nav from '../Nav';
import MenuMobile from '../MenuMobile';
import { ContentWrapper, HeaderContainer, LogoContainer } from './styles';

export default function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <HeaderContainer>
      <MenuMobile open={menuOpen} onClose={e => setMenuOpen(e)} />
      <ContentWrapper>
        <LogoContainer>
          <FaBars
            className="mobileMenu"
            size={20}
            onClick={() => setMenuOpen(true)}
          />
          <Link to="/">
            <img src={Logo} alt="Fideliti Logo" />
          </Link>
        </LogoContainer>
        <Nav />
      </ContentWrapper>
    </HeaderContainer>
  );
}
