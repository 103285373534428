import styled from 'styled-components';
import { simpleFlex, container } from '../../styles/mixins';

export const HeaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  width: 100%;
  background-color: white;
  @media (min-width: 992px) {
    padding: 2px 0px;
  }

  @media (min-width: 840px) {
    .mobileMenu {
      display: none;
    }
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  img {
    height: 50px;
    margin-left: 5px;
  }
  @media (min-width: 1140px) {
    img {
      height: 80px;
      margin-left: 0px;
    }
  }
`;

export const ContentWrapper = styled.div`
  ${simpleFlex}
  ${container}
  height: 66px;
  max-height: 66px;
  min-height: 66px;
  justify-content: space-between;
`;
