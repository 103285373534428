import styled from 'styled-components';
import { simpleFlex, container } from '../../styles/mixins';

export const FooterContainer = styled.div`
  background-color: white;
  padding: 20px 0px;
  color: rgba(0, 0, 0, 0.87);
`;

export const Container = styled.div`
  ${container}
  ${simpleFlex}

  div {
    &:last-child {
      ul {
        margin-top: 20px;
        margin-bottom: 62px;
      }
    }
  }

  @media (max-width: 848px) {
    > div {
      flex: 1 !important;
      text-align: center;

      &:last-child {
        display: none !important;
      }
    }
  }

  > div:last-child {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  > div:first-child {
    ul {
      list-style: none;
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      margin-top: 20px;

      @media (max-width: 848px) {
        justify-content: space-between;
        li {
          margin: 0px !important;
        }
      }

      li {
        display: inline;
        margin-right: 15px;

        &.no-border {
          a {
            border: 0px !important;

            svg {
              margin-right: 5px;
            }
          }
        }

        a {
          display: flex;
          padding: 13px;
          color: rgba(0, 0, 0, 0.87);
          border: 1px solid rgba(0, 0, 0, 0.87);
          border-radius: 100%;

          svg {
            font-size: 16px;
          }
        }
      }
    }
  }
`;
