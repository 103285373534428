import styled, { keyframes } from 'styled-components';

const opacityAnime = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const WhatsappContainer = styled.div`
  animation: ${opacityAnime} 4s linear;
  right: 15px;
  width: 60px;
  bottom: 16px;
  height: 60px;
  display: flex;
  z-index: 9;
  position: fixed;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.24), 0px 0px 6px rgba(0, 0, 0, 0.12);
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  background-color: #25d366;

  svg {
    color: white;
    font-size: 42px;
  }

  &:hover {
    cursor: pointer;
  }
`;
