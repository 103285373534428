import React from 'react';
import { func, bool } from 'prop-types';
import { Link } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';

import { MenuContainer } from './styles';

export default function MenuMobile({ open, onClose }) {
  const close = () => {
    onClose(false);
  };
  return (
    <MenuContainer open={open}>
      <FaTimes onClick={close} />
      <ul onClick={close}>
        <li>
          <Link to="/estoque">Estoque</Link>
        </li>
        <li>
          <Link to="/busca_carro">Procurando algum carro?</Link>
        </li>
        <li>
          <Link to="/contato">Contato</Link>
        </li>
        <li>
          <Link to="/sobre">Sobre a Fideliti</Link>
        </li>
        <li>
          <Link to="/vendemos-seu-carro">Vender</Link>
        </li>
      </ul>
    </MenuContainer>
  );
}

MenuMobile.proptype = {
  open: bool,
  onClose: func,
};
