import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';

const lazyDefault = page => lazy(() => import(`../pages/${page}`));

export default () => (
  <Switch>
    <Suspense fallback={<></>}>
      <Route exact path="/" component={lazyDefault('Home')} />
      <Route path="/vendemos-seu-carro" component={lazyDefault('Vender')} />
      <Route path="/sobre" component={lazyDefault('Sobre')} />
      <Route path="/estoque" component={lazyDefault('Estoque')} />
      <Route path="/detalhe/:id" component={lazyDefault('Detalhes')} />
      <Route path="/busca_carro" component={lazyDefault('BuscaCarro')} />
      <Route path="/contato" component={lazyDefault('Contato')} />
      <Route path="/proposta" component={lazyDefault('Proposta')} />
      <Route path="/:make/:makeId" component={lazyDefault('Estoque')} />
    </Suspense>
  </Switch>
);
