import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import GlobalStyles from './styles/global';
import theme from './styles/theme';
import '../node_modules/react-image-gallery/styles/css/image-gallery.css';

import Routes from './routes';
import Header from './components/Header';
import Footer from './components/Footer';
import WhatsappFloat from './components/WhatsappFloat';
import ScrollToChangePage from './components/ScrollToChangePage';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <BrowserRouter>
        <Header />
        <Routes />
        <WhatsappFloat />
        <Footer />
        <ScrollToChangePage />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
